import { NewsArticleParams } from "features/NewsEvents/entities";
import { useRouter } from "next/router";
import { useRegion } from "core/hooks/useRegion";
import { endOfDay, startOfDay } from "date-fns";
import { useApiNew } from "core/hooks/useApiNew";
import { useBrand } from "core/hooks/useBrand";

export function useNewsWidgetArticles(
  category: string | null,
  maxResultCount: number = 4
) {
  const { locale } = useRouter();
  const { brandCode } = useBrand();
  const region = useRegion();
  const baseParams: NewsArticleParams = {
    Brands: [brandCode],
    Regions: [region],
    Categories: category ? [category] : undefined,
    Locale: locale!,
    Sorting: "publishedTime desc",
    AvailableFromMax: endOfDay(new Date()).toUTCString(),
    AvailableToMin: startOfDay(new Date()).toUTCString(),
    MaxResultCount: maxResultCount,
  };
  const {
    data: featuredData,
    isError: isFeaturedError,
    isLoading: isFeaturedLoading,
  } = useApiNew({
    path: "/api/news-events/articles/News/public",
    parameters: {
      query: {
        ...baseParams,
        IsFeatured: true,
      },
    },
    shouldFetch: Boolean(category),
  });
  const {
    data: notFeaturedData,
    isError: notFeaturedError,
    isLoading: notFeaturedLoading,
  } = useApiNew({
    path: "/api/news-events/articles/News/public",
    parameters: {
      query: {
        ...baseParams,
        IsFeatured: false,
      },
    },
    shouldFetch: Boolean(category),
  });

  const items =
    featuredData &&
    notFeaturedData &&
    Array.isArray(featuredData?.items) &&
    Array.isArray(notFeaturedData?.items)
      ? [...featuredData.items, ...notFeaturedData.items].slice(
          0,
          maxResultCount
        )
      : [];

  return {
    items,
    isError: isFeaturedError || notFeaturedError,
    isLoading: isFeaturedLoading || notFeaturedLoading,
  };
}
