import { FormEventHandler, HTMLAttributeAnchorTarget, useRef } from "react";
import { useTranslation } from "react-i18next";
import PlusIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import Tab from "@mui/material/Tab";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import type { IMenuItem } from "core/entities";
import { IDocLibraryDto, WithSurfacedTranslation } from "features/DMS/entities";
import { NotificationBadge } from "features/Notifications/NotificationBadge";
import { NavSectionCategories } from "./NavSectionCategories";
import { Link } from "../Link";

interface NavSectionProps {
  displayName: string;
  canOpen: boolean;
  open: boolean;
  url?: string | undefined;
  target?: HTMLAttributeAnchorTarget | undefined;
  sectionId: string;
  submenus: IMenuItem[];
  libraries: WithSurfacedTranslation<IDocLibraryDto>[];
  changeTab: () => void;
}
export function NavSection({
  canOpen,
  open,
  url,
  target,
  displayName,
  sectionId,
  submenus,
  libraries,
  changeTab,
}: NavSectionProps) {
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const tabRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:NavSection" });
  const paperRef = useRef<HTMLDivElement>(null);
  const handleClick: FormEventHandler<HTMLDivElement> = () => {
    if (!open) {
      // this setTimeout is needed to prevent the focus from being stolen by the tab
      setTimeout(() => {
        paperRef.current?.focus();
      }, 0);
      changeTab();
      return;
    }
    setTimeout(() => {
      tabRef.current?.focus();
    }, 0);
    changeTab();
  };
  if (!canOpen && url) {
    return (
      <NotificationBadge
        sx={{
          "& .MuiBadge-badge": {
            right: `4px`,
            top: `35px`,
          },
        }}
        id={sectionId}
        mode="either"
      >
        <Tab
          component={Link}
          href={url}
          label={displayName}
          target={target}
          tabIndex={0}
          sx={{
            textTransform: "initial",
            fontSize: "1rem",
          }}
        />
      </NotificationBadge>
    );
  }
  if (!canOpen && !url) {
    return (
      <NotificationBadge
        sx={{
          "& .MuiBadge-badge": {
            right: `8px`,
            top: `35px`,
          },
        }}
        id={sectionId}
        mode="either"
      >
        <Tab
          label={displayName}
          disableRipple
          tabIndex={0}
          sx={{
            "&:hover": { cursor: "default" },
            color: "text.secondary",
            textTransform: "initial",
            fontSize: "1rem",
          }}
        />
      </NotificationBadge>
    );
  }
  return (
    <>
      <NotificationBadge
        sx={{
          "& .MuiBadge-badge": {
            right: `8px`,
            top: `35px`,
          },
        }}
        id={sectionId}
        mode="either"
      >
        <Tab
          label={displayName}
          icon={<ArrowDropUp />}
          iconPosition="end"
          tabIndex={0}
          sx={{
            textTransform: "initial",
            fontSize: "1rem",
            "& .MuiSvgIcon-root": {
              transition: "all 200ms ease",
              transform: `rotate(${open ? 0 : 180}deg)`,
            },
          }}
          ref={anchorRef}
          onChange={handleClick}
        />
      </NotificationBadge>
      <Popper
        slotProps={{ root: { tabIndex: 0 } }}
        open={open}
        onKeyUp={(event) => {
          if (event.key === "Escape") {
            if (open) {
              anchorRef.current?.focus();
            }
            changeTab();
          }
        }}
        keepMounted
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        style={{ zIndex: 11 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              ref={paperRef}
              tabIndex={0}
              component="section"
              elevation={3}
              sx={{ overflow: "hidden", zIndex: 1 }}
            >
              <NavSectionCategories
                libraries={libraries}
                submenus={submenus}
                displayName={displayName}
                sectionId={sectionId}
              />
              {url && (
                <Stack direction="row" justifyContent="flex-end" padding={2}>
                  <NotificationBadge id={sectionId} mode="self">
                    <Button
                      startIcon={<PlusIcon />}
                      variant="contained"
                      LinkComponent={Link}
                      href={url}
                      target={target}
                    >
                      {t("goTo", { displayName })}
                    </Button>
                  </NotificationBadge>
                </Stack>
              )}
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
