import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useAPIDelete } from "core/hooks/useAPIDelete";
import { useToaster } from "core/hooks/useToaster";
import { useCategories } from "../hooks";

interface SubCategoryDeleteProps {
  id: string;
  parentId: string;
  cancelOnClick: () => void;
  canManageLibraryContents: boolean;
}

export function SubCategoryDelete({
  id,
  parentId,
  cancelOnClick,
  canManageLibraryContents,
}: SubCategoryDeleteProps) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:SubCategoryDelete",
  });
  const { mutate } = useCategories({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });
  const { successToast, errorToast } = useToaster();
  const apiDelete = useAPIDelete();

  async function onDeleteClick() {
    try {
      await apiDelete(`/api/app/doc-category/${id}`);
      successToast(t("successToastMessage"));
    } catch {
      errorToast(t("errorToastMessage"));
    }

    mutate((data) => {
      if (!data) {
        return { items: [], totalCount: 0 };
      }
      const newItems = data.items.filter((category) => category.id !== id);
      return { items: newItems, totalCount: newItems.length };
    });
    cancelOnClick();
  }
  return (
    <Box>
      <Typography
        variant="h5"
        mx="auto"
        mb={4}
        textAlign="center"
        maxWidth="65ch"
      >
        {t("warningMessage")}
      </Typography>
      <Typography
        variant="h5"
        mx="auto"
        mb={4}
        textAlign="center"
        maxWidth="65ch"
      >
        {t("confirmMessage")}
      </Typography>
      <Stack spacing={2} direction="row" justifyContent="center">
        <Button
          variant="outlined"
          color="warning"
          onClick={cancelOnClick}
          size="large"
        >
          {t("cancelButton")}
        </Button>
        <Button
          variant="contained"
          color="error"
          size="large"
          onClick={onDeleteClick}
        >
          {t("deleteButton")}
        </Button>
      </Stack>
    </Box>
  );
}
