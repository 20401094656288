import { ErrorComponent } from "core/components/ErrorComponent";
import Box from "@mui/material/Box";
import { ApplicationLoadingDisplay } from "core/components/ApplicationLoadingDisplay";
import { useEffect } from "react";
import { useExtensoToken } from "core/hooks/useExtensoToken";
import { useRouter } from "next/router";
import { ThemeProvider } from "@mui/material/styles";
import { ExtensoTheme } from "core/theme";

export function ExtensoRedirect() {
  const { replace, query } = useRouter();
  const { setToken } = useExtensoToken();
  const { lg, region, token, path, params } = query;
  const areParamsValid = lg && region && token && path;

  useEffect(() => {
    if (areParamsValid) {
      setToken(String(token));
      document.cookie = `authorization_token=${token};SameSite=strict;Path=/`;
      replace(
        {
          pathname: `/${region}/${path}`,
          query: params ? String(params) : undefined,
        },
        undefined,
        {
          locale: String(lg),
        }
      );
    }
  }, [areParamsValid, lg, params, path, region, replace, setToken, token]);

  if (!areParamsValid) {
    // eslint-disable-next-line no-console
    console.error({ token, lg, region, path, params });
    return (
      <ThemeProvider theme={ExtensoTheme}>
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorComponent
            apiError={{
              // Note:  This component exists outside of the normal loading process.
              //        Translations are not available
              message: "Error: Missing query params",
              name: "QueryParamError",
            }}
          />
        </Box>
      </ThemeProvider>
    );
  }
  const locale = typeof lg === "string" ? lg : undefined;

  return (
    <ThemeProvider theme={ExtensoTheme}>
      <ApplicationLoadingDisplay locale={locale} />
    </ThemeProvider>
  );
}
