import OpenInNew from "@mui/icons-material/OpenInNew";
import Avatar from "@mui/material/Avatar";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useFormatDate } from "core/hooks/useFormatDate";
import { useUserLog } from "core/hooks/useUserLog";
import { useIsEditMode } from "features/DMS/hooks";
import { useDmsModalContext } from "features/DMS/hooks/useDmsModal";
import { IDocDocumentPermissionDto } from "features/DMS/entities/DmsDefs";
import { DmsDocumentListItemFile } from "./DmsDocumentListItemFile";
import { DmsDocumentListItemTemplate } from "./DmsDocumentListItemTemplate";
import { ImageAvatar } from "./ImageAvatar";

export interface DmsDocumentListItemProps {
  id: string;
  name: string;
  parentId: string;
  publishTime?: string | Date;
  fileName?: string;
  documentLink?: string;
  docLibraryId: string;
  fileId?: string;
  thumbnailId?: string;
  thumbnailName?: string;
  description?: string;
  availableTo?: string | Date;
  permissions: IDocDocumentPermissionDto[];
  showPermissions: boolean;
}

export function DmsDocumentListItem({
  fileId,
  fileName,
  documentLink,
  docLibraryId,
  id,
  publishTime,
  availableTo,
  parentId,
  thumbnailId,
  thumbnailName,
  permissions,
  ...rest
}: DmsDocumentListItemProps) {
  const isEditMode = useIsEditMode(docLibraryId);
  const { openEditDocumentModal, openDeleteDocumentModal, libraryId } =
    useDmsModalContext();
  const { addLog } = useUserLog();
  async function log() {
    await addLog("DmsDocument", id);
  }
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:DMSDocumentListItem",
  });
  const formatDate = useFormatDate({ dateStyle: "short" });

  const addedDate = t("addedLabel", {
    publishTime: (publishTime && formatDate(publishTime)) || "",
  });
  const expiryDate =
    availableTo &&
    t("expiresLabel", {
      availableTo: formatDate(availableTo),
    });

  function onEditClick(e: MouseEvent) {
    openEditDocumentModal(id, parentId);
    e.stopPropagation();
  }

  function onDeleteClick(e: MouseEvent) {
    openDeleteDocumentModal(id, parentId);
    e.stopPropagation();
  }

  const sharedProps = {
    ...rest,
    id,
    onDeleteClick,
    onEditClick,
    addedDate,
    expiryDate,
    log,
    isEditMode,
  };
  if (documentLink) {
    return (
      <DmsDocumentListItemTemplate
        docLibraryId={libraryId}
        listItemButtonProps={{
          href: documentLink,
          target: "_blank",
        }}
        AvatarSlot={
          thumbnailId && thumbnailName ? (
            <ImageAvatar
              thumbnailId={thumbnailId}
              thumbnailName={thumbnailName}
            />
          ) : (
            <Avatar
              sx={{
                bgcolor: "divider",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              <OpenInNew color="inherit" />
            </Avatar>
          )
        }
        permissions={permissions}
        {...sharedProps}
      />
    );
  }
  if (fileName && fileId) {
    return (
      <DmsDocumentListItemFile
        docLibraryId={libraryId}
        fileId={fileId}
        fileName={fileName}
        thumbnailId={thumbnailId}
        thumbnailName={thumbnailName}
        permissions={permissions}
        {...sharedProps}
      />
    );
  }
  return null;
}
