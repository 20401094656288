import { isDevelopment, isMSWEnabled } from "config";
import { BrandCode } from "brandRegionConfig";
import { FixHubPermissions } from "./permissions";
import { AvailableRoutes } from "./availableRoutes";

interface PagePermission {
  /** If set, at least one of these permissions must be granted to the user */
  hasSomePermissions?: FixHubPermissions[]; //
  /** If set, all of these permissions must be granted to the user */
  hasAllPermissions?: FixHubPermissions[];
  /** Disables the page for all users */
  disabled?: boolean;
  /** If set, limits the page to the specified brands */
  availableForBrands?: BrandCode[];
}

const PaymentRequestSecurity: PagePermission = {
  hasSomePermissions: ["Fixhub.PaymentRequests"],
};

const CmsEditorSecurity: PagePermission = {
  hasSomePermissions: [
    "CmsKit.BlogPosts.Create",
    "CmsKit.BlogPosts.Update",
    "CmsKit.BlogPosts.Delete",
    "CmsKit.BlogPosts.Publish",
  ],
};

const NewsEventSecurity: PagePermission = {
  hasSomePermissions: ["NewsEvents.Articles", "NewsEvents.Categories"],
};

/**
 * Map of routes to their required permissions. Used by PagePermissionChecker component to determine if a user has access to a page.
 */
export const routeAccessMap = new Map<AvailableRoutes, PagePermission>([
  ["/[region]/payment-request", PaymentRequestSecurity],
  ["/[region]/payment-request/add", PaymentRequestSecurity],
  ["/[region]/payment-request/[paymentRequestId]", PaymentRequestSecurity],
  ["/[region]/payment-request/[paymentRequestId]/edit", PaymentRequestSecurity],
  ["/[region]/test-page", { disabled: !isDevelopment }],
  ["/[region]/reports/shop-performance", { disabled: !isMSWEnabled }],
  ["/[region]/reports/shop-performance/[id]", { disabled: !isMSWEnabled }],
  ["/[region]/reports/waiting-list", { disabled: !isMSWEnabled }],
  ["/[region]/reports/vehicle-location", { disabled: !isMSWEnabled }],
  ["/[region]/reports/vehicle-location/[id]", { disabled: !isMSWEnabled }],
  ["/[region]/cms-editor", CmsEditorSecurity],
  ["/[region]/cms-editor/[id]", CmsEditorSecurity],
  ["/[region]/cms-editor/create", CmsEditorSecurity],
  [
    "/[region]/shop-hours-management",
    {
      hasSomePermissions: ["SharedEntities.ShopHours"],
    },
  ],
  [
    "/[region]/shop-hours-management/admin",
    {
      hasAllPermissions: [
        "SharedEntities.ShopHours.Edit",
        "SharedEntities.ShopHours.BrandlessAdmin",
      ],
    },
  ],
  // Publication Management
  ["/[region]/manage/publications/[[...publicationTab]]", NewsEventSecurity],
  [
    "/[region]/manage/publications/articles/create",
    { hasSomePermissions: ["NewsEvents.Articles.Create"] },
  ],
  [
    "/[region]/manage/publications/articles/[id]",
    { hasSomePermissions: ["NewsEvents.Articles.Edit"] },
  ],
  [
    "/[region]/manage/publications/categories/create",
    { hasSomePermissions: ["NewsEvents.Categories.Create"] },
  ],
  [
    "/[region]/manage/publications/categories/[id]",
    { hasSomePermissions: ["NewsEvents.Categories.Edit"] },
  ],
  [
    "/[region]/manage/publications/labels/create",
    { hasSomePermissions: ["NewsEvents.Categories.Create"] },
  ],
  [
    "/[region]/manage/publications/labels/[id]",
    { hasSomePermissions: ["NewsEvents.Categories.Edit"] },
  ],
  // Advertisement Banners
  [
    "/[region]/manage/advertisement-banner",
    { hasSomePermissions: ["Fixhub.AdvertisementBanners"] },
  ],
  [
    "/[region]/manage/advertisement-banner/create",
    { hasSomePermissions: ["Fixhub.AdvertisementBanners.Create"] },
  ],
  [
    "/[region]/manage/advertisement-banner/[id]",
    { hasSomePermissions: ["Fixhub.AdvertisementBanners.Edit"] },
  ],
  ["/[region]/novus-products", { availableForBrands: ["novusglass"] }],
  [
    "/[region]/shop-sales/create/[[...shopId]]",
    {
      hasAllPermissions: [
        "Sales.SaleShop.CanUploadSales",
        "Sales.SaleShop.CanGetShopSalesAdjusments",
        "Sales.ShopsSaleAdjustments.SalesSubmissionUploads",
      ],
    },
  ],
]);
