export enum SearchableEntityType {
  DocLibrary = "DocLibrary",
  DocDocument = "DocDocument",
}

export type DMSSortingOption = `${"name" | "creationTime"} ${"asc" | "desc"}`;

export type DMSFormType =
  | "LibraryForm"
  | "CategoryForm"
  | "SubCategoryForm"
  | "DocumentForm";

export enum TranslationKey {
  En = "en",
  Fr = "fr",
  Es = "es",
  DE = "de-DE",
  HI = "hi",
  IT = "it",
  SK = "sk",
  SL = "sl",
  TR = "tr",
  AR = "ar",
}

export type WithSurfacedTranslation<
  T extends { translations: IDocTranslationDto[] },
> = T & {
  name: string;
  description?: string | undefined;
  transKey: string;
};

export type WithSurfacedAttachment<
  T extends {
    attachments: IDocDocumentAttachmentDto[];
  },
> = T & {
  fileId: string | undefined;
  fileName: string | undefined;
  documentLink: string | undefined;
  thumbnailId: string | undefined;
  thumbnailName: string | undefined;
};

export type IDocDocumentAttachmentCreateUpdateDto = {
  transKey: TranslationKey;
  fileName?: string | undefined;
  fileId?: string | undefined;
  thumbnailId?: string | undefined;
  thumbnailName?: string | undefined;
  documentLink?: string | undefined;
};

export type CorporatePermission = {
  id: string;
  brand?: string | undefined;
  region?: string | undefined;
  roleId?: string | undefined;
  departmentName?: string | undefined;
};

export type ShopPermission = {
  id: string;
  brand?: string | undefined;
  region?: string | undefined;
  shopRoleId?: string | undefined;
  fnShopId?: string | undefined;
};

export type IDocTranslationDto = {
  id?: string | undefined;
  transKey: TranslationKey;
  name: string;
  description?: string | undefined;
  tags?: string[] | undefined;
  isDefault?: boolean;
};

export type IDocLibraryDto = {
  id: string;
  creationTime?: string;
  creatorId?: string | undefined;
  lastModificationTime?: string | undefined;
  lastModifierId?: string | undefined;
  menuItemId?: string | undefined;
  translations: IDocTranslationDto[];
  code?: string | undefined;
  canManage?: boolean;
  permissions: IDocLibraryPermissionDto[];
};

export type IDocLibraryPermissionDto = {
  id: string;
  brand?: string | undefined;
  region?: string | undefined;
  roleId?: string | undefined;
  departmentName?: string | undefined;
};

export type IDocLibraryCreateUpdateDto = {
  menuItemId?: string | undefined;
  tags?: string[] | undefined;
  permissions: IDocLibraryPermissionDto[];
  translations: IDocTranslationCreateUpdateDto[];
};

export type TranslationsObject = {
  [key in TranslationKey]?: IDocTranslationDto;
};

export type AttachmentsObject = {
  [key in TranslationKey]?: IDocDocumentAttachmentDto;
};

export type LibraryFormValues = {
  menuItemId?: string | undefined;
  tags?: string[] | undefined;
  permissions: IDocLibraryPermissionDto[];
  translations: TranslationsObject;
};

// CATEGORY TYPES
export type IDocTranslationCreateUpdateDto = {
  id?: string | undefined;
  transKey?: TranslationKey;
  name?: string | undefined;
  description?: string | undefined;
  tags?: string[] | undefined;
  isDefault?: boolean;
};

export type IDocCategoryCreateUpdateDto = {
  parentId?: string | undefined;
  docLibraryId: string;
  translations: IDocTranslationCreateUpdateDto[];
};

export type IBaseDocCategoryDto = {
  id?: string;
  creationTime?: Date;
  creatorId?: string | undefined;
  lastModificationTime?: Date | undefined;
  lastModifierId?: string | undefined;
  translations?: IDocTranslationDto[] | undefined;
};

export type CategoryFormValues = {
  id?: string | undefined;
  translations: TranslationsObject;
};

// DOCUMENT TYPES
export type IDocDocumentDto = {
  id: string;
  creationTime?: Date;
  creatorId?: string | undefined;
  lastModificationTime?: Date | undefined;
  lastModifierId?: string | undefined;
  availableFrom?: Date | undefined;
  availableTo?: Date | undefined;
  publishTime?: Date | undefined;
  parentId: string;
  tags?: string[] | undefined;
  parentCode?: string | undefined;
  parents?: any[] | undefined;
  translations: IDocTranslationDto[];
  attachments: IDocDocumentAttachmentDto[];
  permissions?: IDocDocumentPermissionDto[] | undefined;
};

export type IDocCategoryDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | undefined;
  lastModificationTime?: string | undefined;
  lastModifierId?: string | undefined;
  translations: IDocTranslationDto[];
  parentId?: string | undefined;
  docLibraryId?: string | undefined;
  docSubCategories?: IBaseDocCategoryDto[] | undefined;
  permissions?: IDocLibraryPermissionDto[] | undefined;
  code?: string | undefined;
  canEdit?: boolean;
  canDelete?: boolean;
};

export type IDocPermissionItemDto = {
  brand: string;
  region: string;
  roleId?: string | undefined;
  departmentName?: string | undefined;
  shopRoleId?: string | undefined;
  shopId?: string | undefined;
};

export type IDocDocumentPermissionDto = {
  id?: string | undefined;
  brand: string;
  region: string;
  roleId?: string | undefined;
  departmentName?: string | undefined;
  shopRoleId?: string | undefined;
  shopId?: string | undefined;
  shopName?: string | undefined;
};

export type IDocDocumentCreateUpdateDto = {
  availableFrom?: Date | undefined;
  availableTo?: Date | undefined;
  publishTime?: Date | undefined;
  parentId: string;
  translations: IDocTranslationCreateUpdateDto[];
  permissions: IDocPermissionItemDto[];
  attachments: IDocDocumentAttachmentCreateUpdateDto[];
};

export type DocumentFormValues = {
  availableFrom?: Date | undefined | null;
  availableTo?: Date | undefined | null;
  attachments?: AttachmentsObject;
  translations?: TranslationsObject;
  isDocumentUpload?: boolean;
  isVisibleToShops?: boolean;
  permissions?: IDocDocumentPermissionDto[];
};

export interface ViewablePermissionsFormValues {
  toggle: "departments" | "roles";
  isVisibleToShops: boolean;
  brands: string[];
  regions: string[];
  departments: string[];
  corporateRoles: string[];
  shops: string[];
  shopRoles: string[];
}

export type NewDocumentFormValues = {
  availableFrom: Date | null;
  availableTo: Date | null;
  attachments?: AttachmentsObject;
  translations: TranslationsObject;
  isDocumentUpload: boolean;
  isVisibleToShops: boolean;
  permissions: ViewablePermissionsFormValues;
};

export type ISearchableEntityDto = {
  id?: string;
  creationTime?: Date;
  creatorId?: string | undefined;
  lastModificationTime?: Date | undefined;
  lastModifierId?: string | undefined;
  type?: SearchableEntityType;
  translations?: IDocTranslationDto[] | undefined;
  attachments?: IDocDocumentAttachmentDto[] | undefined;
};

export type WidgetTabValues = "publishTime" | "lastModificationTime";

export type IDocDocumentAttachmentDto = {
  id: string;
  transKey: TranslationKey;
  fileName?: string | undefined;
  fileId?: string | undefined;
  thumbnailId?: string | undefined;
  thumbnailName?: string | undefined;
  documentLink?: string | undefined;
};

export type AnyViewableDocumentDto = {
  attachments: IDocDocumentAttachmentDto[];
  availableFrom?: string | undefined;
  availableTo?: string | undefined;
  creationTime: string;
  creatorId: string;
  id: string;
  lastModificationTime?: string | undefined;
  lastModifierId?: string;
  parentCode: string;
  parentId: string;
  parents: (IDocLibraryDto | IDocCategoryDto)[];
  translations: IDocTranslationDto[];
  publishTime: string;
};

export type DMSWidgetListItemParent = {
  id: string;
  href?: string;
  name: string;
};

export interface DMSWidgetLinkListItemData {
  id: string;
  type: "link";
  name: string;
  publishTime: string;
  lastModificationTime?: string | undefined | null;
  parents: DMSWidgetListItemParent[];
  tags: string[];
  documentLink: string;
}

export interface DMSWidgetFileListItemData {
  id: string;
  type: "file";
  name: string;
  publishTime: string;
  lastModificationTime?: string | undefined | null;
  parents: DMSWidgetListItemParent[];
  tags: string[];
  fileId: string;
  fileName: string;
}

export type DMSWidgetListItemData =
  | DMSWidgetLinkListItemData
  | DMSWidgetFileListItemData;
