import { HTMLAttributeAnchorTarget, MouseEventHandler, ReactNode } from "react";
import HelpOutline from "@mui/icons-material/HelpOutline";
import Collapse from "@mui/material/Collapse";
import ListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useNotifications } from "features/Notifications/useNotifications";
import { NotificationDot } from "features/Notifications/NotificationDot";
import { IDocDocumentPermissionDto } from "features/DMS/entities/DmsDefs";
import { DmsPermissionsDisplay } from "../DmsPermissionsDisplay";

export interface DmsDocumentListItemTemplateProps {
  isEditMode: boolean;
  onEditClick: MouseEventHandler<HTMLButtonElement>;
  onDeleteClick: MouseEventHandler<HTMLButtonElement>;
  listItemButtonProps?: ListItemButtonProps & {
    href?: string;
    target?: HTMLAttributeAnchorTarget;
  };
  AvatarSlot: ReactNode;
  addedDate: string;
  id: string;
  name: string;
  description?: string;
  expiryDate?: string;
  showPermissions: boolean;
  permissions: IDocDocumentPermissionDto[];
  docLibraryId: string;
}

export function DmsDocumentListItemTemplate({
  id,
  isEditMode,
  onEditClick,
  onDeleteClick,
  listItemButtonProps,
  AvatarSlot,
  name,
  description,
  addedDate,
  expiryDate,
  showPermissions,
  permissions,
  docLibraryId,
}: DmsDocumentListItemTemplateProps) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:DocumentManagementSystem:DMSDocumentListItemTemplate",
  });
  const { markNotificationAsReadByItemId } = useNotifications();
  return (
    <Box
      data-testid="dms-document-list-item"
      onClick={() => {
        markNotificationAsReadByItemId(id);
      }}
      sx={{
        mb: 1,
        backgroundColor: "background.paper",
        borderRadius: 1,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        flexDirection: "column",
      }}
    >
      <ListItem
        sx={{ p: 0 }}
        secondaryAction={
          <Fade in={isEditMode}>
            <Box>
              <IconButton onClick={onEditClick}>
                <EditIcon color="primary" />
              </IconButton>
              <IconButton onClick={onDeleteClick}>
                <DeleteIcon color="primary" />
              </IconButton>
            </Box>
          </Fade>
        }
      >
        <ListItemButton {...listItemButtonProps} sx={{ py: 2, px: 2 }}>
          <ListItemAvatar>{AvatarSlot}</ListItemAvatar>
          <Box
            display="grid"
            alignItems="center"
            flex={1}
            gap={4}
            gridTemplateColumns="1fr 4fr 50px"
          >
            <Stack>
              <Typography variant="h6" color="primary">
                {name} <NotificationDot mode="self" id={id} />
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {addedDate}
              </Typography>
              {expiryDate && (
                <Typography variant="subtitle2" color="text.secondary">
                  {expiryDate}
                </Typography>
              )}
            </Stack>
            {description ? (
              <Tooltip
                PopperProps={{ sx: { whiteSpace: "pre-wrap" } }}
                title={description ?? null}
              >
                <Typography
                  variant="body2"
                  sx={{
                    maxWidth: "100ch",
                    color: "text.secondary",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "no-wrap",
                  }}
                >
                  {description}
                </Typography>
              </Tooltip>
            ) : (
              // placeholder to fill grid area
              <div />
            )}
          </Box>
        </ListItemButton>
      </ListItem>
      <Collapse in={showPermissions} sx={{ width: "100%" }}>
        <Box width="100%">
          <Divider sx={{ width: "100%", mb: 2 }}>
            <Tooltip title={t("permissionsTooltip")}>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                color="text.secondary"
              >
                <Typography variant="overline">
                  {t("permissionsDividerText")}
                </Typography>
                <HelpOutline
                  sx={{ mb: 0.25 }}
                  color="inherit"
                  fontSize="small"
                />
              </Stack>
            </Tooltip>
          </Divider>
          <DmsPermissionsDisplay
            sx={{
              width: "100%",
              px: 4,
              pb: 2,
              flex: 1,
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
              gap: 2,
            }}
            permissions={permissions}
            docLibraryId={docLibraryId}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
