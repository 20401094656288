import { API_URL } from "config";
import { paths } from "resources/api-schema";
import { ExtractParameters } from "core/entities/APITypes";
import { templateRoute } from "./templateRoute";

/**
 * Builds a URL based on the provided route and parameters.
 * @param route - a route defined in `api-schema.d.ts` to be appended to the base URL. Can contain placeholders.
 * @param parameters - The parameters to be included in the URL. parameters are defined in `api-schema.d.ts` Can include path and query parameters.
 * @returns The constructed URL string.
 * @example
 * ```typescript
 *  const url = buildUrl({
 *    route: "/api/person/{id}/group/{groupId}",
 *    parameters: {
 *      path: { id: "123", groupId: "456" },
 *      query: { filter: "active" },
 *    },
 *  });
 *  console.log(url) //"https://api.example.com/api/person/123/group/456?filter=active"
 * ```
 */
export function buildUrl<T extends keyof paths>({
  path,
  parameters,
}: {
  path: T;
  parameters:
    | ExtractParameters<T, "get">
    | ExtractParameters<T, "post">
    | ExtractParameters<T, "put">
    | ExtractParameters<T, "delete">
    | ExtractParameters<T, "patch">
    | undefined;
}) {
  if (!parameters || typeof parameters !== "object") {
    return new URL(path, API_URL).toString();
  }
  let originalRoute: string = path;
  if (
    "path" in parameters &&
    typeof parameters.path === "object" &&
    parameters.path
  ) {
    originalRoute = templateRoute(
      path,
      parameters.path as Record<string, string>
    );
  }
  const apiUrl = new URL(originalRoute, API_URL);
  if (!("query" in parameters) || !parameters.query) {
    return apiUrl.toString();
  }
  Object.entries(parameters.query)
    .sort((a, b) => (a[0] > b[0] ? 1 : -1))
    .forEach(([key, value]) => {
      if (value === undefined) {
        return;
      }
      if (Array.isArray(value)) {
        value.forEach((v) => apiUrl.searchParams.append(key, v));
        return;
      }
      apiUrl.searchParams.append(key, value);
    });
  return apiUrl.toString();
}
