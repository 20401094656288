import { useAPIAll } from "core/hooks/useAPIAll";
import { useMemo } from "react";
import { IDocLibraryDto } from "features/DMS/entities";
import { GenericAPIData } from "core/entities";
import { useContainerProcessor } from "./useContainerProcessor";

/**
 * Returns an array of libraries with the current locale translations surfaced.
 * If the user has DocLibrary Crud permissions the endpoint will return all libraries
 * Otherwise it will return the libraries the user has permission to manage
 */
export function useManageableLibraries(shouldFetch: boolean = true) {
  const processLibrary = useContainerProcessor();
  const key = shouldFetch ? "/api/app/doc-library" : null;
  const { data, isLoading, isError, mutate } = useAPIAll<
    GenericAPIData<IDocLibraryDto>
  >(shouldFetch ? key : null);

  const processedData = useMemo(() => {
    if (isLoading || isError) {
      return undefined;
    }
    const { items = [], totalCount } = data || {};
    const processedItems = items?.map((lib) => processLibrary(lib));
    return { totalCount, items: processedItems };
  }, [data, processLibrary, isLoading, isError]);

  return {
    data: processedData,
    isLoading,
    isError,
    mutate,
  };
}
