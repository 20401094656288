import { createTheme } from "@mui/material/styles";
import deepmerge from "@mui/utils/deepmerge";
import { ThemeOptions } from "@mui/material";
import { BaseThemeObject } from "./BaseThemeObject";

const SpeedyAutoThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      lightest: "#ecf7ff",
      lighter: "#86c9ff",
      light: "#209cff",
      main: "#0067b9",
      dark: "#004b86",
      darker: "#002e53",
      darkest: "#001220",
    },
    secondary: {
      main: "#e52723",
    },
    background: {
      default: "#E6EBF0",
      paper: "#FAFAFA",
    },
  },
};

export const SpeedyAutoTheme = createTheme(
  deepmerge(BaseThemeObject, SpeedyAutoThemeOptions)
);
