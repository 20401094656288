import { useMemo } from "react";
import { BrandCode } from "core/entities";
import { ALL_VALUE } from "config";
import { useTranslation } from "react-i18next";
import { useApiNew } from "./useApiNew";

export function useDepartmentOptions(brands: BrandCode[]) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:useDepartmentOptions",
  });
  const { data, isLoading, isError } = useApiNew({
    path: "/api/shared-entities/organization-unit/departments",
    parameters: { query: { brandUnitCodes: brands } },
  });
  const departmentOptions = useMemo(() => {
    const departments = data
      ? [...new Set(data.map(({ displayName }) => displayName))]
      : [];
    const options = departments
      .map((name) => ({ label: name, value: name }))
      .filter((option): option is { label: string; value: string } => {
        if (option.value && option.value !== "Shops") {
          return true;
        }
        return false;
      });
    return [{ label: t("allDepartments"), value: ALL_VALUE }, ...options];
  }, [data, t]);

  const departmentBrandMap = useMemo(
    () =>
      data?.reduce<Record<string, string[]>>((obj, { brand, displayName }) => {
        if (!brand || !displayName) {
          return obj;
        }
        const brandArray = obj[displayName];
        if (!brandArray) {
          return { ...obj, [displayName]: [brand] };
        }
        if (!brandArray.includes(brand)) {
          return { ...obj, [displayName]: [...brandArray, brand] };
        }
        return obj;
      }, {}),
    [data]
  );

  return { data, isLoading, isError, departmentOptions, departmentBrandMap };
}
