import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { SelectOption } from "core/entities";
import { useTranslation } from "react-i18next";

export interface MultiSelectProps
  extends Partial<AutocompleteProps<any, boolean, boolean, boolean, any>> {
  onChange: (event: any, selectedOptions: any) => void;
  label: string;
  id?: string;
  options: SelectOption[];
  placeholder?: string;
  values: any[];
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  testId?: string;
}

export function MultiSelect({
  id,
  label = "",
  defaultValue = [],
  options = [],
  placeholder = "",
  sx = {},
  values,
  disabled = false,
  onChange = () => {},
  isOptionEqualToValue = (option: any, value: any) => {
    if (typeof option === "string" && typeof value === "string") {
      return option === value;
    }
    return option?.value === value?.value;
  },
  loading,
  loadingText,
  testId,
}: MultiSelectProps) {
  const filteredOptions = options.filter(({ value }) => value != null);
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:Autocomplete",
  });
  return (
    <Autocomplete
      noOptionsText={t("noOptions")}
      data-testid={testId}
      isOptionEqualToValue={isOptionEqualToValue}
      value={values}
      onChange={onChange}
      sx={sx}
      multiple
      loading={loading}
      loadingText={loadingText}
      limitTags={2}
      id={id}
      getOptionLabel={({ label: optionLabel, value }) =>
        String(optionLabel || value)
      }
      defaultValue={defaultValue}
      options={filteredOptions}
      disabled={disabled}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
    />
  );
}
