import { createTheme } from "@mui/material/styles";
import deepmerge from "@mui/utils/deepmerge";
import type { ThemeOptions } from "@mui/material";
import { BaseThemeObject } from "./BaseThemeObject";

const NovusGlassThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#0c5ea9",
    },
    secondary: {
      main: "#e7184e",
    },
    background: {
      default: "#E6EBF0",
      paper: "#FAFAFA",
    },
  },
};

export const NovusGlassTheme = createTheme(
  deepmerge(BaseThemeObject, NovusGlassThemeOptions)
);
