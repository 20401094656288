import { useRouter } from "next/router";
import { useMemo } from "react";
import { IMenuItem } from "core/entities";
import { MenuItemType } from "core/entities/IMenuItem";
import { useContainerProcessor } from "features/DMS/hooks/useContainerProcessor";
import { useAPIAllImmutable } from "./useAPIAllImmutable";

// APB doesn't allow for undefined url parameter, so we're treating "#" as undefined
export const URL_UNDEFINED_CHARACTER = "#";

export function useMenuItems(menuItemType: MenuItemType = "Header") {
  const { locale } = useRouter();
  const processLibrary = useContainerProcessor();
  const { data, isLoading, isError, mutate } = useAPIAllImmutable<IMenuItem[]>({
    path: "/api/app/menu-item",
    params: { locale },
  });
  const returnObject = useMemo(() => {
    if (isLoading || isError) {
      return { isLoading, isError, data: undefined, mutate };
    }

    const menuItems = data
      ?.filter((item) => item.menuItemType === menuItemType)
      .map(({ libraries = [], url, submenus, ...rest }) => {
        const processedLibraries = libraries.map(processLibrary);
        const sectionURL = url === URL_UNDEFINED_CHARACTER ? undefined : url;
        const processedSubmenus = submenus.map((category) => {
          const categoryURL =
            category.url === URL_UNDEFINED_CHARACTER ? undefined : category.url;
          return {
            ...category,
            url: categoryURL,
            submenus: category.submenus,
          };
        });
        return {
          libraries: processedLibraries,
          url: sectionURL,
          submenus: processedSubmenus,
          ...rest,
        };
      });
    return { data: menuItems, isError, isLoading, mutate };
  }, [isLoading, isError, mutate, data, menuItemType, processLibrary]);

  return returnObject;
}
