import { useApiNew } from "core/hooks/useApiNew";
import { useRouter } from "next/router";
import { components } from "resources/api-schema";

type NewsEventType = Extract<
  components["schemas"]["Fixnetwork.NewsEvents.Categories.NewsEventAggregation"],
  "newstype" | "eventtype"
>;

export function useNewsEventsTypes(type: NewsEventType) {
  const { locale } = useRouter();
  return useApiNew({
    path: "/api/news-events/categories/CategoriesSummaries",
    parameters: {
      query: {
        Aggregation: type,
        Locale: locale,
      },
    },
  });
}
