import { useState } from "react";
import List from "@mui/material/List";
import LinearProgress from "@mui/material/LinearProgress";
import { ErrorComponent } from "../ErrorComponent";
import { useMobileMenuItems } from "./useMobileMenuItems";
import { MobileNavRootMenuItem } from "./MobileNavRootMenuItem";

export function MobileNavMenuItems() {
  const [openId, setOpenId] = useState<null | string>(null);
  const { isError, isLoading, menuItems } = useMobileMenuItems();
  if (isError) {
    return <ErrorComponent apiError={isError} />;
  }
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <List>
      {menuItems.map((props) => (
        <MobileNavRootMenuItem
          key={props.id}
          {...props}
          open={openId === props.id}
          onExpandClick={() => {
            if (openId === props.id) {
              setOpenId(null);
              return;
            }
            setOpenId(props.id);
          }}
        />
      ))}
    </List>
  );
}
