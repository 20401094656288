import { useState, HTMLAttributeAnchorTarget } from "react";
import Divider from "@mui/material/Divider";
import { useUserLog } from "core/hooks/useUserLog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { IMenuItem } from "core/entities";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { NotificationDot } from "features/Notifications/NotificationDot";
import { Link } from "../Link";

export interface NavCategoryLinksProps {
  id: string;
  href?: string | undefined;
  target?: HTMLAttributeAnchorTarget | undefined;
  displayName: string;
  submenus: IMenuItem[];
  numberOfInitiallyVisibleLinks: number;
}
export const testIds = {
  seeAllButton: "see-all-button",
};

export function NavCategoryLinks({
  id,
  displayName,
  href,
  target,
  submenus,
  numberOfInitiallyVisibleLinks,
}: NavCategoryLinksProps) {
  const { addLog } = useUserLog();
  const [isExpanded, setIsExpanded] = useState(false);
  const visibleSubmenus = isExpanded
    ? submenus
    : submenus.slice(0, numberOfInitiallyVisibleLinks);
  const isShowAllButtonVisible =
    !isExpanded && submenus.length > numberOfInitiallyVisibleLinks;
  return (
    <List sx={{ width: "24ch" }}>
      <ListItem disableGutters>
        {href ? (
          <Link href={href} target={target} variant="button">
            {displayName}{" "}
            <NotificationDot translate={{ x: 0, y: 0 }} mode="self" id={id} />
          </Link>
        ) : (
          <Typography variant="button">{displayName}</Typography>
        )}
      </ListItem>
      <Divider />
      <TransitionGroup>
        {visibleSubmenus.map(
          ({
            id: linkId,
            url: linkURL,
            displayName: linkDisplayName,
            target: linkTarget,
          }) =>
            !linkURL ? null : (
              <Collapse key={linkId}>
                <ListItem disableGutters>
                  <Link
                    onClick={async () => {
                      if (linkTarget !== "_blank") {
                        return;
                      }
                      await addLog("External", linkURL);
                    }}
                    href={linkURL}
                    underline="none"
                    color="primary.dark"
                    target={linkTarget}
                  >
                    {linkDisplayName}
                    <NotificationDot mode="self" id={linkId} />
                  </Link>
                </ListItem>
              </Collapse>
            )
        )}
      </TransitionGroup>
      <Collapse in={isShowAllButtonVisible}>
        <ListItem
          disableGutters
          sx={{
            p: 0,
            my: 1,
          }}
        >
          <ListItemButton
            data-testid={testIds.seeAllButton}
            onClick={() => setIsExpanded(true)}
            sx={{ justifyContent: "center" }}
          >
            <KeyboardDoubleArrowDownIcon color="primary" />
          </ListItemButton>
        </ListItem>
      </Collapse>
    </List>
  );
}
