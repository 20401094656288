import { createTheme } from "@mui/material/styles";
import deepmerge from "@mui/utils/deepmerge";
import type { ThemeOptions } from "@mui/material";
import { BaseThemeObject } from "./BaseThemeObject";

const CREThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#09253e",
    },
    secondary: {
      main: "#e7184e",
    },
    background: {
      default: "#E6EBF0",
      paper: "#FAFAFA",
    },
  },
};

export const CRETheme = createTheme(
  deepmerge(BaseThemeObject, CREThemeOptions)
);
