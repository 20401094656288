import { removeNullAndEmptyStringValuesFromObject } from "core/utils/removeNullAndEmptyStringValuesFromObject";
import {
  AttachmentsObject,
  IDocDocumentAttachmentCreateUpdateDto,
  IDocDocumentCreateUpdateDto,
  NewDocumentFormValues,
} from "../../entities";
import { formStateToPermissionSet } from "../PermissionManagement/ViewablePermissionsManagement/utils";

function processAttachments(
  attachments: AttachmentsObject | undefined,
  isDocumentUpload: boolean
): IDocDocumentAttachmentCreateUpdateDto[] {
  if (!attachments) {
    return [];
  }
  return Object.values(attachments)
    .filter(
      ({ fileId, documentLink, fileName }) =>
        documentLink || (fileId && fileName)
    )
    .map((attachment) => {
      const { fileName, fileId, documentLink, thumbnailId, thumbnailName, id } =
        removeNullAndEmptyStringValuesFromObject(attachment);
      if (isDocumentUpload) {
        return {
          id,
          thumbnailId,
          thumbnailName,
          transKey: attachment.transKey,
          fileName,
          fileId,
        };
      }
      return {
        id,
        thumbnailId,
        thumbnailName,
        transKey: attachment.transKey,
        documentLink,
      };
    });
}

export function processNewDocumentFormValues(
  data: NewDocumentFormValues,
  parentId: string
): IDocDocumentCreateUpdateDto {
  const {
    isVisibleToShops,
    isDocumentUpload,
    attachments: attachmentsObject,
    translations: translationsObject,
    permissions: rawPermissions,
    ...rest
  } = data;
  const attachments = processAttachments(attachmentsObject, isDocumentUpload);
  const permissions = formStateToPermissionSet(rawPermissions);
  const translations =
    (translationsObject && Object.values(translationsObject)) || [];
  const { availableFrom, availableTo } =
    removeNullAndEmptyStringValuesFromObject(rest);
  const submitData = {
    availableFrom,
    availableTo,
    permissions,
    attachments,
    translations,
    parentId,
  };
  return submitData;
}
