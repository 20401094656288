import { APIError, GenericAPIData, ReqPath } from "core/entities";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import useSWRInfinite from "swr/infinite";
import { useAPIGetJson } from "core/hooks/useAPIGetJson";
import { Paths } from "core/entities/APITypes";
import { DMSSortingOption, IDocDocumentDto } from "../entities";
import { useDocumentProcessor } from "./useDocumentProcessor";
import { useLibraryQueryStateSorting } from "./useLibraryQueryStateSorting";

interface UseInfiniteDocumentsConfig {
  mode: "view" | "manage";
  parentId: string;
  maxResultCount?: number;
  shouldFetch?: boolean;
  canManageLibraryContents: boolean;
}

function getKeyGetter({
  shouldFetch = true,
  maxResultCount = 20,
  parentId,
  locale,
  sorting,
  path,
}: {
  shouldFetch?: boolean;
  maxResultCount?: number;
  parentId: string;
  locale: string;
  sorting: DMSSortingOption;
  path: Paths;
}) {
  function getKey(index: number, previousPageData: any): null | ReqPath {
    if (!shouldFetch) {
      return null;
    }
    if (previousPageData && previousPageData.items.length < maxResultCount) {
      return null;
    }
    return {
      path,
      params: {
        parentId,
        maxResultCount,
        locale,
        sorting,
        skipCount: index * maxResultCount,
      },
    };
  }
  return getKey;
}

export function useInfiniteDocuments({
  parentId,
  mode,
  maxResultCount = 20,
  shouldFetch = true,
  canManageLibraryContents,
}: UseInfiniteDocumentsConfig) {
  const [sorting] = useLibraryQueryStateSorting();
  const { locale = "en" } = useRouter();
  const processDocument = useDocumentProcessor();
  const APIGet = useAPIGetJson();
  const keyGetterOptions = {
    parentId,
    maxResultCount,
    locale,
    sorting,
    shouldFetch,
  };
  const getManageKey = getKeyGetter({
    path: "/api/app/doc-document/manageable-list",
    ...keyGetterOptions,
    shouldFetch: canManageLibraryContents && shouldFetch,
  });
  const getViewKey = getKeyGetter({
    path: "/api/app/doc-document/viewable-list",
    ...keyGetterOptions,
  });

  const manageDocumentsSWRObject = useSWRInfinite<
    GenericAPIData<IDocDocumentDto>,
    APIError
  >(getManageKey, APIGet, { revalidateAll: true });

  const viewDocumentsSWRObject = useSWRInfinite<
    GenericAPIData<IDocDocumentDto>,
    APIError
  >(getViewKey, APIGet, { revalidateAll: true });

  const {
    data,
    documents,
    error,
    setSize,
    size,
    totalCount,
    isValidating,
    loadMore,
    currentCount,
  } = useMemo(() => {
    const localSetSize =
      mode === "manage"
        ? manageDocumentsSWRObject.setSize
        : viewDocumentsSWRObject.setSize;
    const localSize =
      mode === "manage"
        ? manageDocumentsSWRObject.size
        : viewDocumentsSWRObject.size;
    const localData =
      mode === "manage"
        ? manageDocumentsSWRObject.data
        : viewDocumentsSWRObject.data;
    const localError =
      mode === "manage"
        ? manageDocumentsSWRObject.error
        : viewDocumentsSWRObject.error;
    const localIsValidation =
      mode === "manage"
        ? manageDocumentsSWRObject.isValidating
        : viewDocumentsSWRObject.isValidating;

    const processedDocuments =
      localData
        ?.flat()
        .flatMap((res) => res?.items.map((doc) => processDocument(doc))) ?? [];
    const localCurrentCount = processedDocuments?.length || 0;
    return {
      isValidating: localIsValidation,
      data: localData,
      error: localError,
      setSize: localSetSize,
      size: localSize,
      loadMore: () => {
        localSetSize((currentSize) => {
          const newSize = currentSize + 1;
          return newSize;
        });
      },
      documents: processedDocuments,
      totalCount: localData?.[0]?.totalCount || 0,
      currentCount: localCurrentCount,
    };
  }, [
    manageDocumentsSWRObject.data,
    manageDocumentsSWRObject.error,
    manageDocumentsSWRObject.isValidating,
    manageDocumentsSWRObject.setSize,
    manageDocumentsSWRObject.size,
    mode,
    processDocument,
    viewDocumentsSWRObject.data,
    viewDocumentsSWRObject.error,
    viewDocumentsSWRObject.isValidating,
    viewDocumentsSWRObject.setSize,
    viewDocumentsSWRObject.size,
  ]);

  const mutate = useCallback(
    (
      mutateData?: Parameters<typeof manageDocumentsSWRObject.mutate>[0],
      opts?: Parameters<typeof manageDocumentsSWRObject.mutate>[1]
    ) => {
      if (mutateData && opts) {
        manageDocumentsSWRObject.mutate(mutateData, opts);
        return Promise.all([
          viewDocumentsSWRObject.mutate(),
          manageDocumentsSWRObject.mutate(mutateData, opts),
        ]);
      }
      if (mutateData) {
        return Promise.all([
          manageDocumentsSWRObject.mutate(mutateData),
          viewDocumentsSWRObject.mutate(),
        ]);
      }
      return Promise.all([
        viewDocumentsSWRObject.mutate(),
        manageDocumentsSWRObject.mutate(),
      ]);
    },
    [manageDocumentsSWRObject, viewDocumentsSWRObject]
  );

  const showLoadMore = documents && documents.length < totalCount;
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = Boolean(
    isLoadingInitialData ||
      (size > 0 && data && typeof data[size - 1] === "undefined")
  );
  return {
    isValidating,
    mutate,
    setSize,
    size,
    data,
    error,
    isLoadingInitialData,
    isLoadingMore,
    loadMore,
    showLoadMore,
    documents,
    totalCount,
    currentCount,
  };
}
