import Paper from "@mui/material/Paper";
import { CustomModal } from "core/components/CustomModal";
import { MenuActions } from "features/DMS/entities";
import { LibraryAdd } from "./LibraryAdd";
import { LibraryEdit } from "./LibraryEdit";
import { LibraryDelete } from "./LibraryDelete";
import { CategoryAdd } from "./CategoryAdd";
import { CategoryEdit } from "./CategoryEdit";
import { CategoryDelete } from "./CategoryDelete";
import { SubCategoryAdd } from "./SubCategoryAdd";
import { SubCategoryDelete } from "./SubCategoryDelete";
import { SubCategoryEdit } from "./SubCategoryEdit";
import { DocumentAdd } from "./DocumentForm/DocumentAdd";
import { DocumentEdit } from "./DocumentForm/DocumentEdit";
import { DocumentDelete } from "./DocumentDelete";

const actionComponentMap: Record<MenuActions, (props: any) => JSX.Element> = {
  addLibrary: LibraryAdd,
  editLibrary: LibraryEdit,
  deleteLibrary: LibraryDelete,
  addCategory: CategoryAdd,
  editCategory: CategoryEdit,
  deleteCategory: CategoryDelete,
  addSubCategory: SubCategoryAdd,
  editSubCategory: SubCategoryEdit,
  deleteSubCategory: SubCategoryDelete,
  addDocument: DocumentAdd,
  editDocument: DocumentEdit,
  deleteDocument: DocumentDelete,
};

export interface ActionModalProps {
  isModalOpen: boolean;
  action: MenuActions | null;
  id?: string | null;
  parentId?: string | null;
  docLibraryId?: string | null;
  closeModal: () => void;
  canManageLibraryContents?: boolean;
}

export function DmsActionModal({
  isModalOpen,
  action,
  id,
  parentId,
  docLibraryId,
  closeModal,
  canManageLibraryContents = false,
}: ActionModalProps) {
  if (action === null) {
    return null;
  }
  const Component = actionComponentMap[action];
  // Add forms use the the id as the parent id
  // ex. Clicking on the "Add Document" option of
  // a Category's contextual menu will pass the
  // category's Id as the "parentId" prop to the
  // DocumentAdd form
  const props = /add/gi.test(action)
    ? {
        cancelOnClick: closeModal,
        parentId: id,
        docLibraryId,
        canManageLibraryContents,
      }
    : {
        cancelOnClick: closeModal,
        parentId,
        id,
        docLibraryId,
        canManageLibraryContents,
      };
  return (
    <CustomModal
      open={isModalOpen}
      onClose={closeModal}
      sx={{ display: "grid", placeItems: "center" }}
    >
      <Paper
        sx={{
          maxWidth: "80vw",
          minWidth: "min(800px,calc(100vw - 56px))",
          maxHeight: "90vh",
          overflow: "auto",
          bgcolor: "background.default",
          p: 2,
        }}
      >
        {Component && <Component {...props} />}
      </Paper>
    </CustomModal>
  );
}
