import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useRouter } from "next/router";
import MenuIcon from "@mui/icons-material/Menu";
import { MyLinksMenu } from "features/MyLinks/components/MyLinksMenu";
import { useTranslation } from "react-i18next";
import { HeaderNotifications } from "features/Notifications/HeaderNotifications";
import { CommunityFeedback } from "features/Notifications/CommunityFeedback";
import { useCanManage } from "core/hooks/useCanManage";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import { Nav } from "./Nav";
import SearchBar from "./SearchBar";
import { Link } from "./Link";
import { MenuOption, UserMenu } from "./UserMenu";
import { MobileNav } from "./MobileNav";
import { BrandLogo } from "./BrandLogo";

export function GlobalHeader() {
  const [isMobileNavMenuOpen, setIsMobileNavMenuOpen] = useState(false);
  const menuOptions: MenuOption[] = ["user-preferences", "logout"];
  const { events } = useRouter();
  const canManage = useCanManage();
  const { isDesktop } = useBreakpoints();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:GlobalHeader",
  });
  function closeMenu() {
    setIsMobileNavMenuOpen(false);
  }
  useEffect(() => {
    const eventArgs: Parameters<typeof events.on> = [
      "routeChangeStart",
      closeMenu,
    ];
    events.on(...eventArgs);
    return () => {
      events.off(...eventArgs);
    };
  }, [events]);

  if (isDesktop) {
    return (
      <Box
        component="header"
        sx={{
          flexGrow: 1,
          boxShadow: (theme) => theme.shadows[1],
          zIndex: 1,
          displayPrint: "none",
        }}
      >
        <AppBar position="static" component="section">
          <Toolbar
            disableGutters
            sx={{ px: 3, justifyContent: "space-between", gap: 3 }}
          >
            <SearchBar />
            {canManage && (
              <Button
                LinkComponent={Link}
                href="/manage"
                sx={{ color: "primary.contrastText" }}
              >
                {t("manage")}
              </Button>
            )}

            <MyLinksMenu />
            <HeaderNotifications />
            <CommunityFeedback />
            <UserMenu menuItems={menuOptions} />
          </Toolbar>
        </AppBar>
        <Nav />
      </Box>
    );
  }
  // Mobile header
  return (
    <Box
      data-testid="mobile-header"
      component="header"
      sx={{
        flexGrow: 1,

        displayPrint: "none",
        boxShadow: (theme) => theme.shadows[1],
        zIndex: 1,
      }}
    >
      <AppBar position="static" component="section">
        <Toolbar
          disableGutters
          sx={{
            display: "grid",
            justifyItems: "center",
            gridTemplateColumns: "105px 1fr 105px",
            gap: 3,
          }}
        >
          <Stack direction="row">
            <IconButton
              data-testid="open-mobile-menu-button"
              onClick={() => {
                setIsMobileNavMenuOpen(true);
              }}
            >
              <MenuIcon sx={{ color: "primary.contrastText" }} />
            </IconButton>
          </Stack>
          <Box pt={1} maxWidth={175}>
            <Link href="/">
              <BrandLogo />
            </Link>
          </Box>
          <Stack direction="row" justifyContent="end">
            <HeaderNotifications />
            <UserMenu menuItems={menuOptions} />
          </Stack>
        </Toolbar>
      </AppBar>
      <MobileNav open={isMobileNavMenuOpen} handleClose={closeMenu} />
    </Box>
  );
}
