import { createTheme } from "@mui/material/styles";
import deepmerge from "@mui/utils/deepmerge";
import type { ThemeOptions } from "@mui/material";
import { BaseThemeObject } from "./BaseThemeObject";

const SpeedyGlassThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#ee3124",
    },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#E6EBF0",
      paper: "#FAFAFA",
    },
  },
};

export const SpeedyGlassTheme = createTheme(
  deepmerge(BaseThemeObject, SpeedyGlassThemeOptions)
);
