import { ExtractParameters } from "core/entities/APITypes";
import { useApiNew, UseApiNewArg } from "./useApiNew";

export type PublicShopsFilter = ExtractParameters<
  "/api/shared-entities/shops/public",
  "get"
>["query"];

export function usePublicShops(
  filter?: PublicShopsFilter,
  shouldFetch = true,
  swrOptions?: UseApiNewArg<"/api/shared-entities/shops/public">["swrOptions"]
) {
  return useApiNew({
    path: "/api/shared-entities/shops/public",
    parameters: { query: filter },
    shouldFetch,
    swrOptions,
  });
}
