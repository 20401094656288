import { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useMainContentFilter } from "core/hooks/useMainContentFilter";
import { useRouter } from "next/router";
import { useMenuItems } from "core/hooks/useMenuItems";
import { NavSection } from "./NavSection";

export function NavSections() {
  const { 1: setIsFilterOn } = useMainContentFilter();
  const { data: menuItems } = useMenuItems();
  const [tabIndex, setTabIndex] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();
  const [transitionProperty, setTransitionProperty] = useState<
    "transform" | "all"
  >("transform");

  function getHandleChangeTab(index: number, canOpen: boolean) {
    return () => {
      if (!canOpen) {
        return;
      }
      if (tabIndex === index && isMenuOpen) {
        setIsMenuOpen(false);
        setIsFilterOn(false);
        return;
      }
      setTabIndex(index);
      setIsMenuOpen(true);
      setIsFilterOn(true);
    };
  }

  useEffect(() => {
    function closeMenuOnRouteChange() {
      setIsMenuOpen(false);
      setIsFilterOn(false);
    }
    const eventArgs: Parameters<typeof router.events.on> = [
      "routeChangeStart",
      closeMenuOnRouteChange,
    ];
    router.events.on(...eventArgs);
    return () => {
      router.events.off(...eventArgs);
    };
  });

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsMenuOpen(false);
        setIsFilterOn(false);
      }}
    >
      <Tabs
        component="nav"
        value={tabIndex}
        aria-label="navigation tabs"
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{
          onTransitionEnd: () => {
            setTransitionProperty(isMenuOpen ? "all" : "transform");
          },
        }}
        sx={{
          flex: 1,
          "& .MuiTabs-indicator": {
            transform: `scale3d(${isMenuOpen ? 1 : 0},1,1)`,
            transformOrigin: "center",
            transitionProperty,
          },
          "& .MuiTabs-flexContainer": {
            gap: 3,
          },
          "& .MuiTab-root": {
            opacity: 1,
          },
        }}
      >
        {menuItems?.map?.((rootMenuItem, index) => {
          const canOpen = Boolean(
            rootMenuItem.libraries.length || rootMenuItem.submenus.length
          );
          const { submenus, id, libraries, ...props } = rootMenuItem;

          return (
            <NavSection
              canOpen={canOpen}
              open={canOpen && isMenuOpen && tabIndex === index}
              key={id}
              sectionId={id}
              submenus={submenus}
              libraries={libraries}
              {...props}
              changeTab={getHandleChangeTab(index, canOpen)}
            />
          );
        })}
      </Tabs>
    </ClickAwayListener>
  );
}
