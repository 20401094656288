import { useApiNew } from "core/hooks/useApiNew";
import { useRouter } from "next/router";

export function useNewsEventCategories(isFeatured?: boolean | undefined) {
  const { locale } = useRouter();
  return useApiNew({
    path: "/api/news-events/categories/CategoriesSummaries",
    parameters: {
      query: {
        Aggregation: "category",
        Locale: locale,
        IsFeatured: isFeatured,
      },
    },
  });
}
