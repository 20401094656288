import { CRETheme, FixAutoTheme, ProColorTheme } from "core/theme";
import { Brand, BrandCode } from "core/entities";
// import { FixNetworkWorldTheme } from "core/theme/FixNetworkWorldTheme";
import { SpeedyAutoTheme } from "core/theme/SpeedyAutoTheme";
import { NovusGlassTheme } from "core/theme/NovusGlassTheme";
import { SpeedyGlassTheme } from "core/theme/SpeedyGlassTheme";

const BrandMap = new Map<BrandCode, Brand>();

BrandMap.set("novusglass", {
  brandClassification: "Glass",
  brandCode: "novusglass",
  theme: NovusGlassTheme,
  name: "Novus Auto Glass",
});

BrandMap.set("speedyauto", {
  brandClassification: "Mechanical",
  brandCode: "speedyauto",
  theme: SpeedyAutoTheme,
  name: "Speedy Auto",
});

BrandMap.set("speedyglass", {
  brandClassification: "Glass",
  brandCode: "speedyglass",
  theme: SpeedyGlassTheme,
  name: "Speedy Glass",
});

BrandMap.set("fixaut", {
  brandClassification: "Collision",
  brandCode: "fixaut",
  theme: FixAutoTheme,
  name: "Fix Auto",
});

BrandMap.set("proc", {
  brandClassification: "Collision",
  brandCode: "proc",
  theme: ProColorTheme,
  name: "Pro Color",
});

BrandMap.set("cre", {
  brandClassification: "Collision",
  brandCode: "cre",
  theme: CRETheme,
  name: "Collision Repair Experts",
});

export default BrandMap;
